.info-template {
  display: block;
  padding: 20px 0;
  margin: 20px 0;
  border-top: 1px solid $cp7; }

.columns.box-colors {
  .column {
    color: $cb;
    text-shadow: 0 1px 2px $cn;
    padding: 5% 0;
    min-height: 90px;
    text-align: center;
    &.cp1 {
      background: $cp1; }
    &.cp2 {
      background: $cp2; }
    &.cp3 {
      background: $cp3; }
    &.cp4 {
      background: $cp4; }
    &.cp5 {
      background: $cp5; }
    &.cp6 {
      background: $cp6; }
    &.cp7 {
      background: $cp7; }
    &.cp8 {
      background: $cp8; } } }

.template__list {
  li {
    margin: 10px 0;
    font-size: 2rem; } }
.margin-- {
  &top- {
    &1 {
      margin-top: 1em; }
    &2 {
      margin-top: 2em; }
    &3 {
      margin-top: 3em; } }
  &bottom- {
    &1 {
      margin-bottom: 1em; }
    &2 {
      margin-bottom: 2em; }
    &3 {
      margin-bottom: 3em; } } }
