// .......................................................................... { Titles } #
.title-2 {
  font-weight: bold;
  font-size: 1.3rem;
  margin: 0.9rem 0; }

.head {
  &__title {
    font-size: 1.3rem;
    text-transform: uppercase;
    font-weight: 600;
    position: relative;
    z-index: 1;
    letter-spacing: 0.04rem;
    color: $cp5;
    margin-bottom: 16px;
    @include sm {
      margin-bottom: inherit; }

    &--shadow {
      font-weight: 800;
      font-size: 2.5rem;
      position: absolute;
      left: 0;
      top: -13px;
      color: $cp7;
      z-index: -1;
      display: none;

      @include sm {
        font-size: 3.7rem;
        top: -29px; } } }


  &__par {
    margin: 0 0 30px;
    display: block;
    font-weight: 300; }

  &__link {
    position: absolute;
    color: $cp1;
    top: 45px;
    right: 0;
    display: block;
    width: 130px;
    z-index: 2;
    text-align: right;
    font-size: 0.8rem;
    font-weight: 600;
    @include sm {
      right: 10px;
      top: 10px; }

    &:hover {
      color: $cp3; }
    &:hover b {
      color: $cp3;
      border: 1px solid $cp3; }
    b {
      border: 1px solid $cp1;
      color: $cp1;
      text-align: center;
      padding: 0.1rem;
      font-size: 0.8rem;
      margin: 10px 0 10px 10px;
      width: 30px;
      height: 30px;
      display: inline-block;
      line-height: 1.5rem;
      top: 0;
      &::before {
        transform: rotate(-90deg);
        display: inline-block; } } }

  &.head-2 {
    text-align: center;

    .head__title {
      &--shadow {
        left: 35%; } } } }


// Center vertical-align title
.ext--vertical {
  padding-top: 64px;
  br {
    display: none;
    @include sm {
      display: block; } } }

// Center vertical-align title
.ext--vertical--search {
  padding-top: 6%; }

.title__section {
	font-weight: bold;
	color: $cp5;
	font-size: 17px;
	display: block;
	margin-top: 3rem; }

.title__container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin-top: 3rem;
	.title__section {
		margin-top: 0; } }
