// .......................................................................... { man section styles } #

.main-section {
	width: 100%;
	min-height: 10rem;
	padding: 1rem 1rem;
	margin-top: -15rem !important;
	z-index: 2;
	// CHANGE: cambie el valor absolute to relative, para solucionar el problema con el footer
	position: relative;
	overflow: hidden;
	&__inner {
		margin-top: -12rem !important;
		&::after {
			top: 11.8rem !important; }
		@include md {
			margin-top: -8.8rem !important;
			padding: 3rem 1rem;
			&::after {
				top: 8.8rem !important; } }
		@include lg {
			margin-top: -8.8rem !important;
			padding: 3rem 1rem;
			&::after {
				top: 8.8rem !important; } } }
	&::after {
		content: '';
		display: block;
		width: 100%;
		background-color: $cp7;
		height: 100%;
		position: absolute;
		top: 15rem;
		left: 0;
		z-index: -2; }
	@include md {
		margin-top: -9rem !important;
		padding: 3rem 1rem;
		&::after {
			top: 9rem; } }
	@include lg {
		margin-top: -9rem !important;
		padding: 3rem 1rem;
		&::after {
			top: 9rem; } } }
