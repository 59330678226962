
// .......................................................................... { color palette } #
$cp1:     #EC1B2E;
$cp2:     #F5EBEC;
$cp3:     #E6CDD0;
$cp4:     #666C80;
$cp5:     #292C33;
$cp6:     #B8BDCC;
$cp7:     #F5F6FA;
$cp8:     #25282F;
$cp9:     #EBECF0;
$cp10:    #E6CDD0;
$cp11:    #737680;
$cp12:    #FF3347;
$cp13:    #CF0619;
$cp14:    #333843;

// ............... { tones } #

$cb:    #fff;
$cn:    #000;

$cg1:   #ECECEC;
$cg2:   #3F444D;
$cg3:   #EBEBEB;
$cg4:   #E2E2E2;
$cg5:   #333333;
$cg6:   #525252;
$cg7:   #808690;
$cg8:   #AEAEAE;
$cg9:   #737680;
$cg10:   #f8f9fa;
$cg11: #252525;
$cg12: #242424;
$cg13: #252525;
$cg14: #363636;
$cg15: #A8ABB3;
$cg16: #FAFAFA;
$cg17: #B3B3B3;
$cg18: #E6E6E6;
$cg19: #F5F8FA;
$cg20: #EAEBEC;
$cg21: #353A42;

// ............... { alrets } #

$success: #52C40C;
$warning: #FFD340;
$error: #FF5050;
$info: #E0F1FF;

// ............... { social colors } #

$facebook: #3B5998;
$twitter: #5FA9DD;
$pinterest: #c8232c;
$youtube: #FF0000;
$instagram: #5FA9DD;
$whatsapp: #58BA4A;


// ............... { Extranet colors } #

$uniBackground : rgb(250, 250, 250);
$uniGralText : rgb(141, 145, 153);
$uniGraySpoon : #A4A7AF;
$uniHeadingText : rgb(41, 44, 51);
$uniLineColor : rgb(53, 58, 66);
$uniTextColor : rgb(53, 58, 66);
$uniPrimaryColor : rgb(214, 13, 44);
$uniInputs : rgb(234, 235, 236);
$uniLightGray : #C4C6CC;
$uniNotificationGray : #E1E2E6;

// States
$uniPending : #ED9200;
$uniApproved : #26C74B;
$uniRejected : #D60D2C;
$uniProgress : #21DDAA;
$uniSolved : #499DE5;
$uniInactive: #E6E6E6;
$uniTextInactive: #B3B3B3;
