$bpoint-1: 992;
$bpoint-2: 1600;

.client {
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	column-gap: 16px;
	row-gap: 25px;
	align-items: center;
	grid-template-areas: 'status status category category category case'
											'client client client client tags tags'
											'maildate maildate maildate update update update';
	@include md {
		grid-template-columns: repeat(4, 1fr);
		row-gap: 35px;
		grid-template-areas: 'client client client client'
													'category case maildate update';
	}
	@include rwd($bpoint-1) {
		grid-template-columns: repeat(6, 1fr);
		row-gap: 25px;
		grid-template-areas: 'status status category category category case'
											'client client client client tags tags'
											'maildate maildate maildate update update update';
	}
	@include rwd($bpoint-2) {
		grid-template-columns: repeat(4, 1fr);
		grid-template-areas: 'client client client client'
													'category case maildate update';
	}
	&__item {
		&.is--status {
			grid-area: status;
			@include md {
				display: none;
			}
			@include rwd($bpoint-1) {
				display: inline-block;
			}
			@include rwd($bpoint-2) {
				display: none;
			}
		}
		&.is--category {
			grid-area: category;
		}
		&.is--case {
			grid-area: case;
		}
		&.is--client {
			grid-area: client;
			.text-title {
				display: block;
				@include md {
					display: inline-block;
				}
				@include rwd($bpoint-1) {
					display: block;
				}
				@include rwd($bpoint-2) {
					display: inline-block;
				}
			}
			.name {
				display: block;
				@include md {
					display: inline-block;
					margin-left: 20px;
				}
				@include rwd($bpoint-1) {
					display: block;
					margin-left: 0;
				}
				@include rwd($bpoint-2) {
					display: inline-block;
					margin-left: 20px;
				}
			}
			.numberdoc {
				display: block;
				@include md {
					display: none;
				}
				@include rwd($bpoint-1) {
					display: block;
				}
				@include rwd($bpoint-2) {
					display: none;
				}
			}
		}
		&.is--tags {
			grid-area: tags;
			text-align: right;
			@include md {
				display: none;
			}
			@include rwd($bpoint-1) {
				display: block;
			}
			@include rwd($bpoint-2) {
				display: none;
			}
		}
		&.is--maildate {
			grid-area: maildate;
		}
		&.is--update {
			grid-area: update;
			margin-left: auto;
			@include md {
				margin-left: 0;
			}
			@include rwd($bpoint-1) {
				margin-left: auto;
			}
			@include rwd($bpoint-2) {
				margin-left: 0;
			}
		}
		&.is--preicon {
			padding-left: 30px;
			position: relative;
			max-width: 115px;
		}
		.icon {
			position: absolute;
			left: 0;
			font-size: 22px;
			font-weight: bold;
		}
	}
}