@charset "utf-8";

// Update Bulma's global variables
$family-sans-serif: 'Open Sans', sans-serif;
$widescreen-enabled: true;
$fullhd-enabled: false;

// Update some of Bulma's component variables
$control-border-width: 2px;
$input-border-color: transparent;
$input-shadow: none;

// .......................................................................... { global routers } #
$r_global: "../images";

html {
  font-family: 'Open Sans', sans-serif; }

body {
  overflow-x: hidden;
  background-color: white; }
p {
  font-weight: 500;
  color: $cp11; }

a {
  outline: none; }

// .......................................................................... { grid main } #

#cont_pzi {
  //Height of the main nav for some pages with content with {position: absolute}
  padding-top: 80px;
  overflow: hidden;
  @media screen and (min-width: 1280px) {
    padding-top: 120px; } }

.container-shopping-mall-iframe {
	top: 80px;

	@media screen and (min-width: 1280px) {
    top: 120px; } }

.container-shopping-mall-iframe-app {
	top: 0; }

// .......................................................................... { all sliders } #
.slider-wrapper {
  max-width:  100%;
  width: 100%;
  position: relative; }


// .......................................................................... { show / hidden } #
.show-mobile {
  display: block;
  @include md {
    display: block; }
  @include lg {
    display: none; } }

// .......................................................................... { box white } #
.box-white {
  padding: 1.5rem;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 14px 14px rgba(0, 0, 0, 0.07); }

.justify-content-center {
  justify-content: center; }

[data-count-element] {
	pointer-events: none;
	opacity: .3;

	&.count-is-active {
		pointer-events: all;
		opacity: 1; } }

.container-shopping-mall-iframe,
.container-shopping-mall-iframe-app, {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	background: #e7e7e7;
	z-index: 1;

	iframe {
		width: 100%;
		height: 100%; } }
