@charset "utf-8";

// .......................................................................... { font main} #
// Import a Google Font
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800');


// .......................................................................... { font icons} #
$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "../fonts/icons/icomoon" !default;

$icon-action-call: "\e903";
$icon-action-back: "\e902";
$icon-chevron1: "\e93d";
$icon-chevron: "\e929";
$pqr-icon-arrow: "\e902";
$pqr-icon-search: "\e94a";
$pqr-icon-upload: "\e914";
$pqr-icon-image: "\e913";
$pqr-icon-play: "\e912";
$pqr-icon-reply: "\e90a";
$pqr-icon-attach: "\e911";
$pqr-icon-download: "\e90b";
$pqr-icon-doc: "\e904";
$pqr-icon-add: "\e916";
$pqr-icon-close: "\e928";
$pqr-icon-state-inbox-date: "\e91f";
$pqr-icon-state-due-date: "\e91e";
$pqr-icon-check: "\e939";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?g90cze');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?g90cze#iefix') format('embedded-opentype'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?g90cze') format('truetype'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?g90cze') format('woff'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?g90cze##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-world-2:before {
  content: "\e900";
}
.icon-action-export:before {
  content: "\e901";
}
.icon-action-back:before {
  content: "\e902";
}
.icon-action-call:before {
  content: "\e903";
}
.icon-archivos:before {
  content: "\e904";
}
.icon-action-complete:before {
  content: "\e905";
}
.icon-action-edit:before {
  content: "\e906";
}
.icon-action-notifications:before {
  content: "\e907";
}
.icon-action-respond:before {
  content: "\e908";
}
.icon-action-send-mail:before {
  content: "\e909";
}
.icon-action-send-message:before {
  content: "\e90a";
}
.icon-descargar:before {
  content: "\e90b";
}
.icon-action-share:before {
  content: "\e90c";
}
.icon-filter-end-ticket:before {
  content: "\e90d";
}
.icon-filter-progress:before {
  content: "\e90e";
}
.icon-filter-ticket-open:before {
  content: "\e90f";
}
.icon-icon-alert:before {
  content: "\e910";
}
.icon-icon-attach:before {
  content: "\e911";
}
.icon-icon-image-copia:before {
  content: "\e912";
}
.icon-icon-image:before {
  content: "\e913";
}
.icon-icon-upload:before {
  content: "\e914";
}
.icon-menu-add-closer:before {
  content: "\e915";
}
.icon-menu-add:before {
  content: "\e916";
}
.icon-menu-burger:before {
  content: "\e917";
}
.icon-menu-filter:before {
  content: "\e918";
}
.icon-menu-history:before {
  content: "\e919";
}
.icon-menu-profile:before {
  content: "\e91a";
}
.icon-menu-reports:before {
  content: "\e91b";
}
.icon-menu-search:before {
  content: "\e91c";
}
.icon-menu-ticket:before {
  content: "\e91d";
}
.icon-state-due-date:before {
  content: "\e91e";
}
.icon-state-inbox-date:before {
  content: "\e91f";
}
.icon-pqr-icon:before {
  content: "\e920";
}
.icon-filter-gobal:before {
  content: "\e921";
}
.icon-adjuntar:before {
  content: "\e922";
}
.icon-admin_tramites:before {
  content: "\e923";
}
.icon-almacenes:before {
  content: "\e924";
}
.icon-buscar:before {
  content: "\e925";
}
.icon-calendario:before {
  content: "\e926";
}
.icon-carpeta:before {
  content: "\e927";
}
.icon-cerrar:before {
  content: "\e928";
}
.icon-chevron:before {
  content: "\e929";
}
.icon-contactos:before {
  content: "\e92a";
}
.icon-editar:before {
  content: "\e92b";
}
.icon-eliminar:before {
  content: "\e92c";
}
.icon-enviados:before {
  content: "\e92d";
}
.icon-manuales:before {
  content: "\e92e";
}
.icon-mensaje-nuevo:before {
  content: "\e92f";
}
.icon-menu:before {
  content: "\e930";
}
.icon-nuevo:before {
  content: "\e931";
}
.icon-perfil:before {
  content: "\e932";
}
.icon-recibidos:before {
  content: "\e933";
}
.icon-reportes:before {
  content: "\e934";
}
.icon-responder:before {
  content: "\e935";
}
.icon-salir:before {
  content: "\e936";
}
.icon-tramites:before {
  content: "\e937";
}
.icon-ver:before {
  content: "\e938";
}
.icon-check:before {
  content: "\e939";
}
.icon-cambiar-contrasena:before {
  content: "\e93a";
}
.icon-calendar:before {
  content: "\e93b";
}
.icon-check1:before {
  content: "\e93c";
}
.icon-chevron1:before {
  content: "\e93d";
}
.icon-cross:before {
  content: "\e93e" !important;
}
.icon-email:before {
  content: "\e93f";
}
.icon-facebook:before {
  content: "\e940";
}
.icon-filters:before {
  content: "\e941" !important;
}
.icon-heart:before {
  content: "\e942";
}
.icon-hour:before {
  content: "\e943";
}
.icon-instagram:before {
  content: "\e944";
}
.icon-marker:before {
  content: "\e945";
}
.icon-minus:before {
  content: "\e946";
}
.icon-phone:before {
  content: "\e947";
}
.icon-pinterets:before {
  content: "\e948";
}
.icon-plus:before {
  content: "\e949";
}
.icon-search:before {
  content: "\e94a" !important;
}
.icon-twitter:before {
  content: "\e94b";
}
.icon-world:before {
  content: "\e94c";
}
.icon-youtube:before {
  content: "\e94d";
}
.icon-camera:before {
  content: "\e94e";
}
.icon-folder:before {
  content: "\e94f";
}
.icon-file-text:before {
  content: "\e950";
}
.icon-info:before {
  content: "\e951";
}
.icon-whatsapp:before {
  content: "\ea93";
}
