.align-items-center {
	align-items: center; }

.overflow-y--hidden {
	overflow-y: hidden; }

.text--center {
	text-align: center !important; }

.text--bold {
	font-weight: 600 !important; }

.text--right {
	text-align: right; }

.text--small {
	font-size: 11px !important; }

.flex--column {
	flex-direction: column !important; }

.align-items--flex-end {
	align-items: flex-end !important; }

.align-items--center {
	align-items: center !important; }

.justify-content--between {
	justify-content: space-between !important; }

.justify-content--end {
	justify-content: flex-end !important; }

.justify-content--start {
	justify-content: flex-start !important; }

.padding-y--none {
	padding-top: 0 !important;
	padding-bottom: 0 !important; }
