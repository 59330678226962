.gral-btn {
	background-color: $uniPrimaryColor;
	width: 100%;
	font-size: 1em;
	height: 3em;
	display: inline-block;
	justify-content: center;
	align-items: center;
	flex-direction: row;
	font-weight: 600;
	border-radius: 1.5em;
	outline: none;
	margin: 0.7em 0;
	cursor: pointer;
	text-decoration: none;
	color: white !important;
	outline: none !important;
	transition: all 0.5s;
	&.line {
		background-color: white;
		border: solid 2px $uniHeadingText;
		color: $uniHeadingText !important;
		margin-right: 1em;
		&.red {
			border: solid 2px $uniPrimaryColor;
			color: $uniPrimaryColor !important; } }
	&.green {
		background-color: $uniApproved;
		margin-right: 1em; }
	&.gray {
		background-color: #EAEBEC !important;
		border: none;
		color: $uniHeadingText !important;
		margin-right: 1em;
		width: auto;
		padding: 0 2em;
		position: relative;
		display: flex;
		font-size: 0.9em;
		box-shadow: none !important;
		transform: none !important;
		filter: none !important;
		&.stiched {
			padding: 1.2em 2em;
			margin: 10px auto;
			color: $uniGraySpoon !important;
			line-height: 1.3em;
			border: 1px dashed $uniGraySpoon;
			border-radius: 25px;
			box-shadow: 0 0 0 4px #eaebec !important;
			text-align: center;
			height: auto; }
		&:visited {}
		&:focus {
			box-shadow: none !important; }
		[class^="icon-"] {
			font-weight: bold;
			margin-right: 1em; } }
	&.link {
		padding-top: 0.6em; }
	&:active {
		box-shadow: none !important; } }
.custom-switch .custom-control-label {
	cursor: pointer;
	&::after {
		background-color: #fff; }
	&::before {
		border-color: #D60D2C;
		background-color: #D60D2C; } }
.custom-control-input {
	&:checked~.custom-control-label::before {
		border-color: #26C74B;
		background-color: #26C74B; } }
.norm-btn {
	font-size: 1.5rem;
	color: $uniHeadingText;
	&:hover {
		text-decoration: none; }
	&.bold {
		font-weight: bold; } }
.add-btn {
	background-color: $uniPrimaryColor;
	width: 4em;
	height: 4em;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	border: none;
	outline: none;
	right: 1.5em;
	bottom: 1.5em;
	text-decoration: none !important;
	padding: 0;
	z-index: 2;
	-webkit-box-shadow: 0 4px 4px 0 rgba(0,0,0,0.1);
	-moz-box-shadow: 0 4px 4px 0 rgba(0,0,0,0.1);
	-webkit-box-shadow: 0 4px 4px 0 rgba(0,0,0,0.1);
	box-shadow: 0 4px 4px 0 rgba(0,0,0,0.1);
	span {
		color: white !important;
		font-size: 1.9em;
		padding: 0;
		line-height: 0;
		margin: 0; } }
.not-found {
	.add-btn {
		width: 1.5em;
		height: 1.5em;
		display: inline-block;
		text-align: center;
		line-height: 1.65;
		position: inherit;
		margin-left: 0;
		span {
			font-size: 1em; } } }
.modal-btn {
	background-color: white !important;
	border: none;
	font-weight: 400;
	flex: 1;
	margin: 0;
	padding: 1rem;
	color: $uniPrimaryColor !important;
	font-size: 1.1em;
	outline: none !important;
	box-shadow: none !important;
	cursor: pointer;
	&.dark {
		color: $uniGralText !important; }
	&.padding--none {
		padding: 0; } }
.delete-thumb, .delete-file {
	cursor: pointer;
	border-radius: 20px;
	background-color: #fff;
	color: $uniRejected !important;
	padding: 7px;
	font-size: 18px;
	display: inline-block;
	width: 32px;
	-webkit-box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.1);
	-moz-box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.1);
	box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.1); }
.selected {
	color: $uniPrimaryColor; }
// Media Querys

// Media query xs
@media (max-width: 575.98px) {

	.gral-btn {
		width: 100%; } }

// Media query sm
@media (min-width: 576px) and (max-width: 767.98px) {

	.gral-btn {
		width: 100%; } }

// Media query md
@media (min-width: 768px) and (max-width: 991.98px) {

	.gral-btn {
		width: 100%; } }

// Media query lg
@media (min-width: 992px) and (max-width: 1199.98px) {

	.gral-btn {
		&:hover {
			transform: translateY(-5px);
			box-shadow: 0 0.2em 0.3em rgba(gray, 0.4);
			filter: brightness(110%); } }

	.add-btn {
		position: static;
		width: 3em;
		height: 3em;
		margin-left: 1.5em;
		span {
			font-size: 1.5em; } } }

// Media query xlg
@media (min-width: 1200px) {

	.gral-btn {
		&:hover {
			transform: translateY(-5px);
			box-shadow: 0 0.2em 0.3em rgba(gray, 0.4);
			filter: brightness(110%); } }

	.add-btn {
		position: static;
		width: 3em;
		height: 3em;
		margin-left: 1.5em;
		span {
			font-size: 1.5em; } } }
@media (min-width: 768px) {
	.gral-btn {
		&.gray {
			&.stiched {
				padding: 3em 2em 4em; } } }
	.headline {
		1.5rem {} } }
