.button {
  border-style: none;
  border-radius: 0;
  box-shadow: none;
  width: 100%;
  font-size: 0.8rem;
  line-height: 2rem;
  height: 40px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  text-align: center;
  transition: all 0.2s ease-out;

  &__prmimary {
      background: $cp1;
      color: $cb;
      border-radius: 8px;

      &:hover {
        background: $cp12;
        color: $cb !important; } }

  &.m--black {
    background: $cn;
    width: 120px;

    &:hover {
      color: $cb; } }

  &.m--facebook {
      background: $facebook;
      color: $cb;
      max-width: 300px;
      width: 100%;
      position: relative;

      &:hover {
        color: $cb; }

      .icons {
        font-size: 1.3rem;
        position: absolute;
        top: 8px;
        left: 10px; } }


  &.m--whatsapp {
    position: fixed;
    bottom: 10px;
    right: 15px;
    width: auto;
    height: 40px;
    color: transparent;
    background: transparent;
    border-radius: 8px;
    z-index: 500;
    font-size: 0;
    min-width: 45px;

    &:hover {
      color: #CCFFF7; }
    @include sm {
      font-size: 14px;
      color: white;
      background: #1EBEA5;
      font-weight: bold;
      height: 44px;
      padding-left: 64px;
      padding-right: 44px; }

    img {
      position: absolute;
      width: 44px;
      left: 10px;
      @include sm {
        top: -10px; } } }

  &__black-button {
    background-color: $cp5 !important; }

  .back-button-icon {
    margin: 0.5rem;
    transform: rotate(90deg) !important;
    display: block;
    font-size: 1rem; }

  &:hover {
    color: $cn; }

  &--catalogue {
    width: 200px;
    border-radius: 2rem;
    box-shadow: 0 14px 14px rgba($cn, 0.07);
    font-weight: bold;
    font-size: 15px;
    color: $cp10;
    &:hover {
      color: $cp10; }
    .icon {
      transform: rotate(180deg);
      position: relative;
      top: 1px;
      margin-left: 5px !important; } } }


.medium {
  display: block;
  max-width: 300px;
  margin: 0 auto; }

.button-back {
	display: block;
	width: 100%;
	background: $cb;
	border-radius: 2rem;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-size: 16px;
	color: $cp1;
	font-weight: bold;
	box-shadow: 0 4px 10px rgba($cn, 0.1);
	padding: 5px 0;
	outline: none;
	border: none;
	cursor: pointer;
	@include sm {
		max-width: 230px; }
	&.is--secondary {
		color: $cg15; }
	.icon {
		position: relative;
		top: 1px;
		margin-right: 5px; } }
