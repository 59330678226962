// Small smartphones (landscape view)
$screen-xs-min: 320px;

// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 992px;

// Large tablets and desktops
$screen-xl-min: 1200px;


// Small devices
@mixin xs {
   @media (min-width: #{$screen-xs-min}) {
       @content; } }

// Small devices
@mixin sm {
   @media (min-width: #{$screen-sm-min}) {
       @content; } }

// Medium devices
@mixin md {
   @media (min-width: #{$screen-md-min}) {
       @content; } }

// Large devices
@mixin lg {
   @media (min-width: #{$screen-lg-min}) {
       @content; } }

// Extra large devices
@mixin xl {
   @media (min-width: #{$screen-xl-min}) {
      @content; } }

// Landscape
@mixin landscape {
  @media screen and (min-width: #{$screen-xs-min}) and (max-width: 767px) and (orientation: landscape) {
    @content; } }

// Custom devices
@mixin rwd($screen) {
   @media (min-width: $screen+'px') {
       @content; } }


@mixin responsive( $var ) {
  @if($var) {
    html {
      &:before {
        content: 'small';
        position: fixed;
        z-index: 90000;
        top: 0;
        left: 0;
        width: 100%;
        background: rgba(0,0,0,0.7);
        text-align: center;
        padding: 5px 20px;
        font-size: 0.9rem;
        color: $cb; }
      @include xs {
        &:before {
          content: 'xs = 320px'; } }
      // 576px window width and more
      @include sm {
        &:before {
          content: 'sm = 576px'; } }
      // 768x window width and more
      @include md {
        &:before {
          content: 'md = 768px'; } }
      // 992px window width and more
      @include lg {
        &:before {
          content: 'lg = 992px'; } }
      // 1200px window width and more
      @include xl {
        &:before {
          content: 'xl = 1200px'; } } } }
  @else {
    body {
      &:before {
        display: none; } } } }
