$bpoint: 600;

.bill {
	background: $cp1;
	border-radius: 1rem;
	overflow: hidden;
	padding: 1.5rem 1rem 2rem 1rem;
	color: $cb;
	font-size: 15px;
	@include rwd($bpoint) {
		display: grid;
		grid-template-columns: 7fr 5fr;
		grid-template-rows: auto;
		grid-template-areas: 'ar-1 ar-3''ar-2 ar-2';
		gap: 1rem;
		font-size: 22px;
		padding: 2.5rem 2rem;
	}
	&__ar-1 {
		@include rwd($bpoint) {
			grid-area: ar-1;
		}
	}
	&__ar-2 {
		@include rwd($bpoint) {
			grid-area: ar-2;
			margin-top: 8rem;
		}
	}
	&__ar-3 {
		@include rwd($bpoint) {
			grid-area: ar-3;
		}
	}
	&__autor,
	&__points {
		font-weight: bold;
	}
	&__points {
		font-size: 19px;
		margin-bottom: 0.5rem;
		@include rwd($bpoint) {
			font-size: 30px;
			margin-bottom: 1rem;
		}
	}
	&__label {
		color: $cp3;
		@include rwd($bpoint) {
			font-size: 18px;
		}
	}
	&__logo {
		display: none;
	}
	&__data {
		display: grid;
		grid-template-columns: 1fr 1fr;
		margin-bottom: 1rem;
		@include rwd($bpoint) {
			display: flex;
			column-gap: 2rem;
			margin-bottom: 0;
		}
	}
	&__buttons {
		.button {
			border-radius: 2rem;
			font-weight: bold;
			font-size: 16px;
			&__add-bill {
				margin-bottom: 1rem;
				color: $cp1;
				background: $cb;
				.icon {
					font-size: 18px;
					margin-right: 10px !important;
					position: relative;
					top: 1px;
				}
			}
			&__my-bills {
				background: rgba($cb, 0.2);
				color: $cb;
				.icon {
					transform: rotate(180deg);
					position: relative;
					top: 1.5px;
					margin-left: 5px !important;
				}
			}
		}
	}
}