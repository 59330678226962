.alert {
	padding: 2rem 1.5rem;
	border-radius: 0.5rem;
	margin-top: 5rem;
	color: $cb;
	&--black {
		background: $cp5;
	}
	&__icon {
		position: relative;
		top: 2px;
		margin-right: 1rem;
	}
	&__container {
		margin-bottom: 1rem;
		&:last-child {
			margin-bottom: 0;
		}
	}
}

.alerting {
	background: $cg3;
	margin-top: 2rem;
	padding: 1.5rem;
	border-radius: .8rem;
	position: relative;
	&__icon {
		position: absolute;
		top: 50%;
		left: 1.5rem;
		transform: translateY(-50%);
	}
	&--icon {
		padding-left: 3.5rem;
	}
	&.is--error {
		background: $uniRejected;
		p,
		span {
			color: $cb;	
		}
	}
}