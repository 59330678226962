.catalogue-slider {
	padding: 0 0.5rem 2rem 0.5rem;
	&__container {
		position: relative;
		width: 100%;
		max-width: 263px;
		margin: 0 auto;
		background: $cb;
		border-radius: 1rem;
		box-shadow: 0 14px 14px rgba($cn, 0.07);
	}
	&__footer {
		padding: 1rem;
	}
	&__alert {
		position: absolute;
		background: $cp10;
		color: $cb;
		font-weight: bold;
		font-size: 12px;
		padding: 2px 16px;
		border-radius: 1rem;
		top: 10px;
		left: 10px;
	}
	&__image {
		width: 75%;
		margin: 0 auto;
		padding: 1rem 0;
		img {
			width: 100%;
			display: block;
		}
	}
	&__title {
		font-weight: bold;
		color: $cp10;
	}
	&__points {
		font-size: 15px;
		margin-top: 10px;
		color: $cg5;
	}
}

#catalogue-slider {
	margin-top: 1rem;
	.slick-dots {
		bottom: 0 !important;
	}
	.slick-active {
		button {
			&::before {
				color: $cp10 !important;
			}
		}
	}
	.slick-arrow {
		background: $cb;
		border-radius: 50%;
		box-shadow: 0 10px 10px rgba($cn, 0.03);
		height: 44px;
		width: 44px;
		&:hover {
			background: $cp10 !important;
		}
		&::before {
			color: $cp10;
		}
		&.slick-prev {
			right: 55px;
		}
		&.slick-next {
			left: 55px;
			@include rwd(576) {
				left: auto;
			}
		}
	}
}