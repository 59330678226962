.campaign {
	border-radius: 1rem;
	overflow: hidden;
	width: 100%;
	max-width: 263px;
	margin: 0 auto;
	box-shadow: 0px 14px 14px rgba($cn, 0.07);
	&__link {
		display: block;
	}
	&__img {
		width: 100%;
		object-fit: cover;
		display: block;
		&.is--bordered {
			border-radius: 1rem;
		}
		&.is--max-mobile {
			max-width: 500px;
			// margin: 0 auto;
			@include md {
				max-width: 100%;
			}
		}
	}
	&__footer {
		padding: 1rem;
		span {
			font-weight: bold;
			font-size: 15px;
			display: block;
			color: $cg15;
		}
	}
	&__card {
		padding: 4rem 0;
		display: grid;
		grid-template-columns: 1fr;
		row-gap: 1rem;
		@include md {
			grid-template-columns: repeat(2, 1fr);
			column-gap: 1rem;
		}
		@include lg {
			column-gap: 1.5rem;
		}
		&__title {
			color: $cg15;
			font-size: 20px;
			font-weight: bold;
			margin-bottom: 2rem;
		}
		&__text {
			font-size: 15px;
			line-height: 24px;
		}
		&__date {
			font-size: 15px;
			margin: 2rem 0;
			position: relative;
			padding-left: 3rem;
			h6 {
				font-weight: bold;
			}
			span {
				display: block;
			}
			&::before {
				content: '\e926';
				font-family: 'icomoon';
				position: absolute;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
				font-size: 20px;
			}
		}
		&__tyc {
			display: block;
			font-size: 13px;
			color: $cp4;
			margin-bottom: 2rem;
		}
		&__codes {
			margin: 0 0 2rem 0;
			font-size: 13px;
			h6 {
				font-weight: bold;
			}
			span {
				padding-left: 1rem;
				display: block;
			}
			&__title {
				color: $cg15;
				font-weight: bold;
				&.ft--mb {
					margin-bottom: 1rem;
				}
			}
			&__list {
				margin-left: 2rem;
				display: flex;
				column-gap: 1.5rem;
				row-gap: 1rem;
				flex-wrap: wrap;
				li {
					padding-left: 1rem;
					position: relative;
					&::before {
						content: '';
						height: 5px;
						width: 5px;
						border-radius: 50%;
						display: inline-block;
						background: $cp1;
						position: absolute;
						top: 50%;
						left: 0;
						transform: translateY(-50%);
					}
				}
			}
		}
	}
}