// ............................................ { backgrunds }
.background {
  &::after {
    content: '';
    top: 0;
    left: 0;
    position: fixed;
    width: 100%;
    height: 100%;
    background: $cp7;
    z-index: -1; }
  &--blurred {
    overflow: hidden;
    /* Add the blur effect */
    filter: blur(48px);
    -webkit-filter: blur(48px);

    /* Full screen */
    height: calc(100% + 120px);
    width: calc(100% + 120px);
    margin: -60px;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: fixed;
    &::after {
      content: '';
      background-color: rgba(41, 44, 51, 0.7);

      /* Full screen */
      height: 100%;
      width: 100%;

      /* Center and scale the image nicely */
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: fixed; } } }
