.check {
  &__input {
    position: relative;
    padding: 7px 0 7px 23px;
    text-align: left;

    label {
      cursor: pointer;
      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 50%; }

      &:before {
        left: 0;
        width: 17px;
        height: 16px;
        margin: -9px 0 0;
        background: $cb;
        box-shadow: 0 0 1px $cp6; }

      &:after {
        left: 3px;
        width: 10px;
        height: 10px;
        margin: -6px 0 0;
        opacity: 0;
        background: $cp1;
        transform: translate3d(-40px, 0, 0) scale(0.5);
        transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out; } }

    input[type="radio"],
    input[type="checkbox"] {
      position: absolute;
      top: 0;
      left: -9999px;
      visibility: hidden;

      &:checked + label {
        &:before {}
        &:after {
          transform: translate3d(0, 0, 0);
          opacity: 1; } } } }

  &--square {
    label {
      &:before,
      &:after {
        border-radius: 0; } } }

  .styled-input--rounded {
    label {
      &:before {
        border-radius: 10px; }
      &:after {
        border-radius: 6px; } } }

  .styled-input--diamond {
    .styled-input-single {
      padding-left: 45px; }
    label {
      &:before,
      &:after {
        border-radius: 0; }
      &:before {
        transform: rotate(45deg); } }

    input[type="radio"],
    input[type="checkbox"] {

      &:checked + label {
        &:after {
          transform: rotate(45deg);
          opacity: 1; } } } } }
